

































































































































































































































































































































































































































































































































.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
